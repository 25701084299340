import React from "react";
import { FunctionComponent } from "react";
import * as RModal from "react-modal";
import styled from "styled-components";

RModal.setAppElement("#root");

interface IProps {
  modalIsOpen: boolean;
  onCloseModal: () => void;
  clientId: string;
  appId: string;
  setCredentials: ({ clientId, appId }) => void;
}

interface Props extends IProps {}

export const Modal: FunctionComponent<IProps> = ({
  modalIsOpen,
  onCloseModal,
  clientId,
  appId,
  setCredentials,
}: Props) => {
  const [clientIdModalState, setClientIdModalState] = React.useState<string>(
      clientId || ""
    ),
    [appIdModalState, setAppIdModalState] = React.useState<string>(appId || "");

  function onUseFreeKey(): void {
    setCredentials({
      clientId: process.env.REACT_APP_API_CLIENT_ID,
      appId: process.env.REACT_APP_API_APP_ID,
    });
  }

  function onSetCredentials(): void {
    if (credentialsAreValid) {
      setCredentials({ clientId: clientIdModalState, appId: appIdModalState });
    }
  }

  const credentialsAreValid = React.useMemo(() => {
    const appRegex = /^(monitor_|test_)?\w{24}$/;
    const clientRegex = /^\w{24}$/;

    return (
      clientRegex.test(clientIdModalState.trim()) &&
      appRegex.test(appIdModalState.trim())
    );
  }, [clientIdModalState, appIdModalState]);

  return (
    <RModal
      isOpen={modalIsOpen}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <Wrapper>
        <Header>
          <Heading>Set your credentials</Heading>
          <Description>
            The playground utilizes free credentials by default. These
            credentials give access to a limited data set. To get access to the
            full dataset, enter your own credentials below.
          </Description>
        </Header>

        <Body>
          <SubHeading>Enter x-client-id</SubHeading>
          <FormWrapper>
            <Label>
              <Link href="https://account.chargetrip.com/sign-up">
                Get your credentials
              </Link>
            </Label>
            <Input
              type="text"
              data-lpignore="true"
              name="clientid"
              id="clientid"
              onChange={(e) => setClientIdModalState(e.target.value)}
              value={clientIdModalState}
              required
              placeholder="Enter your x-client-id"
              pattern="^\w{24,24}$"
            />
          </FormWrapper>

          <SubHeading>Enter x-app-id</SubHeading>
          <FormWrapper>
            <Input
              type="text"
              data-lpignore="true"
              name="appid"
              id="appid"
              onChange={(e) => setAppIdModalState(e.target.value)}
              value={appIdModalState}
              required
              placeholder="Enter your x-app-id"
              pattern="^(monitor_|test_)?\w{24}$"
            />
          </FormWrapper>

          <ButtonWrapper>
            <SecondaryButton onClick={onCloseModal}>cancel</SecondaryButton>
            <div style={{ display: "flex" }}>
              <SecondaryButton
                onClick={onUseFreeKey}
                style={{ marginRight: "12px" }}
              >
                Use free credentials
              </SecondaryButton>
              <PrimaryButton
                onClick={onSetCredentials}
                disabled={!credentialsAreValid}
              >
                Set credentials
              </PrimaryButton>
            </div>
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </RModal>
  );
};

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    borderWidth: "0px",
    backgroundColor: "transparent",
    padding: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
  },
};

const Header = styled.section`
  padding: 16px 24px;
  background: #0d141a;
`;

const Body = styled.section`
  padding: 16px 24px 24px 24px;
  background: #1f2a38;
`;

const Heading = styled.h2`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 4px;
`;

const SubHeading = styled.h2`
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.8;
  margin-top: 0px;
`;

const Input = styled.input`
  padding: 8px 12px;
  width: 100%;
  height: 40px;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.5px;
  background: #0d141a;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.6);
  &:focus {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }
  &:focus:invalid {
    border: 1px solid red;
  }
  &:valid {
    border: 1px solid #55ab68;
    color: #fff;
  }
`;

const SecondaryButton = styled.button`
  justify-content: center;
  padding: 4px 16px;
  display: flex;
  background: #435764;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff;
  :hover {
    cursor: pointer;
  }
  border-width: 0;
`;

const PrimaryButton = styled.button`
  justify-content: center;
  padding: 4px 16px;
  display: flex;
  background: #55ab68;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: "#fff";
  border-width: 0;
  :hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;

    opacity: 0.7;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  filter: drop-shadow(0px 0px 8px rgba(13, 20, 26, 0.32))
    drop-shadow(0px 0px 24px rgba(13, 20, 26, 0.24));
  border-radius: 4px;
`;

const FormWrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  opacity: 0.8;
`;

const Link = styled.a`
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #55ab68;
  opacity: 0.8;
`;
