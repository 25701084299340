import {
  Playground,
  RootState,
  selectTabIndex,
  store,
  Workspace,
  getSelectedSession,
} from "graphql-playground-react";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { Modal } from ".";
import { getQueryIndex, getTabs, useQuery } from "../utils";
import useCookie from "react-use-cookie";

const BANNER_PROPS = {
    free: {
      bgColor: "#E49435",
      text: 'This playground is currently using demo credentials. You have access to a limited dataset. <a href="https://account.chargetrip.com/sign-up">Create your own project for full access.</a>',
    },
    ownKey: {
      text: "This playground is using custom credentials. You can access the dataset configured for the following project: {KEY}.",
      bgColor: "#55AB68",
    },
  },
  FREE_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID,
  FREE_APP_ID = process.env.REACT_APP_API_APP_ID;

interface IProps {}

interface Props extends IProps {
  workspace: Workspace;
  selectedSession: any;
}

export const PlaygroundWrapper: FunctionComponent<IProps> = connect(
  (state: RootState) => ({
    workspace: state.workspaces.get(state.selectedWorkspace),
    selectedSession: getSelectedSession(state),
  })
)(function ({ workspace, selectedSession }: Props) {
  // Query params
  const query = useQuery(),
    [didShowModal, setDidShowModal] = useCookie("token", "false"),
    [queryIndex, setQueryIndex]: [number, Dispatch<SetStateAction<number>>] =
      useState(-1);

  const sanitizeInput = (input: string): string =>
    input.replace(/[^a-zA-Z0-9_-]/g, "").trim();

  const getSanitizedQueryParam = React.useCallback(
    (key: string): string | null => {
      const value = query.get(key);
      return value ? sanitizeInput(value) : null;
    },
    [query] 
  );

  const queryParamClientId: string | null = getSanitizedQueryParam("clientId"),
    queryParamAppId: string | null = getSanitizedQueryParam("appId");

  // State
  const [clientIdState, setClientIdState] = React.useState<string | null>(
      queryParamClientId
    ),
    [appIdState, setAppIdState] = React.useState<string | null>(
      queryParamAppId
    ),
    [modalIsOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (!queryParamClientId) setIsOpen(didShowModal === "false");
  }, [queryParamClientId, queryParamAppId, didShowModal]);

  // Update URL logic with validation
  const updateURL = React.useCallback(
    ({ clientId, appId }: { clientId: string; appId: string }) => {
      // Sanitize input to prevent malicious characters
      const sanitizeInput = (input: string): string =>
          input.replace(/[^a-zA-Z0-9_-]/g, "").trim(),
        sanitizedClientId = sanitizeInput(clientId),
        sanitizedAppId = sanitizeInput(appId),
        allowedDomains = [
          "https://playground.chargetrip.com",
          "https://staging.playground.chargetrip.com",
        ],
        isAllowedDomain = (url: string) =>
          allowedDomains.some((domain) => url.startsWith(domain));

      // Construct the new URL
      const currentPath = `${window.location.origin}${
        window.location.pathname
      }?page=${selectedSession.name}&clientId=${encodeURIComponent(
        sanitizedClientId
      )}&appId=${encodeURIComponent(sanitizedAppId)}`;

      // Validate the constructed URL against the allowed domains
      if (isAllowedDomain(currentPath)) {
        setDidShowModal("true");
        window.history.replaceState({}, "", currentPath);

        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else {
        console.error("Invalid redirection attempt blocked:", currentPath);
      }
    },
    [selectedSession.name, setDidShowModal]
  );

  /**
   * @description Opens the modal
   * @returns void
   */
  function openModal() {
    setIsOpen(true);
  }

  /**
   * @description Closes the modal.
   * @returns void
   */
  function closeModal() {
    setIsOpen(false);
  }

  /**
   * @description Set the credentials.
   * @param { clientId, appId}
   * @returns void
   */
  function setCredentials({
    clientId,
    appId,
  }: {
    clientId: string;
    appId: string;
  }): void {
    // Sanitize the inputs
    const sanitizeInput = (input: string) => input.replace(/[<>]/g, "").trim(),
      sanitizedClientId = clientId ? sanitizeInput(clientId) : null,
      sanitizedAppId = appId ? sanitizeInput(appId) : null;

    if (sanitizedAppId) {
      // Ensure appId conforms to the expected format
      if (sanitizedAppId.startsWith("monitor_")) {
        setAppIdState(sanitizedAppId.substring(0, 32));
      } else if (sanitizedAppId.startsWith("test_")) {
        setAppIdState(sanitizedAppId.substring(0, 29));
      } else {
        setAppIdState(sanitizedAppId);
      }
    }

    if (sanitizedClientId) {
      // Validate clientId format
      const clientIdRegex = /^\w{24}$/;
      if (clientIdRegex.test(sanitizedClientId)) {
        setClientIdState(sanitizedClientId);
      } else {
        console.error("Invalid clientId format");
        return; // Stop further processing if invalid
      }
    }

    closeModal();

    // Update the URL safely
    updateURL({ clientId: sanitizedClientId, appId: sanitizedAppId });
  }

  /**
   * Patch the Playground and add a SET CREDENTIALS button.
   */
  useEffect(() => {
    const res = document.querySelector("#root > div > div > div > div")
      .childNodes[0];

    const resNav = res.childNodes[0] as HTMLElement;

    resNav.id = "tabbar-scroller";

    const button = document.querySelectorAll("button")[0],
      parent = button.parentNode,
      key =
        !queryParamClientId || FREE_CLIENT_ID === queryParamClientId
          ? "free"
          : "ownKey",
      bannerExists = !!document.getElementById("bannerWrapper");
    let buttonClone = button.cloneNode(true) as HTMLElement;
    buttonClone.innerHTML = "Set credentials";
    buttonClone.onclick = openModal;
    buttonClone.id = buttonClone.id + "2";
    parent.prepend(buttonClone);

    if (!bannerExists) {
      const banner = document.createElement("DIV");
      banner.className = "banner";
      banner.id = "banner";

      const bannerWrapper = document.createElement("DIV");
      bannerWrapper.className = "bannerWrapper";
      bannerWrapper.id = "bannerWrapper";
      bannerWrapper.appendChild(banner);

      parent.parentNode.insertBefore(bannerWrapper, parent.nextSibling);
    }
    const banner = document.getElementById("banner");
    banner.innerHTML = BANNER_PROPS[key].text.replace(
      "{KEY}",
      queryParamClientId
    );
    banner.style.backgroundColor = BANNER_PROPS[key].bgColor;
  }, [queryParamClientId]);

  /**
   * Set the active playground by URL and include the client id and app id query params.
   */
  useEffect(() => {
    const page: string = getSanitizedQueryParam("page"),
      newQueryIndex = page ? getQueryIndex(page) : -1;

    if (newQueryIndex >= 0 && newQueryIndex !== queryIndex) {
      const sessionsArray = workspace.sessions.sessions.toArray();
      if (
        sessionsArray &&
        sessionsArray[newQueryIndex] &&
        sessionsArray[newQueryIndex][0] &&
        selectedSession.id !== sessionsArray[newQueryIndex][0]
      ) {
        setQueryIndex(newQueryIndex);
        store.dispatch(selectTabIndex(newQueryIndex));
      }
    } else if (typeof selectedSession.name === "string") {
      query.set("page", selectedSession.name);

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${query.toString()}`
      );
    }
  }, [query, queryIndex, workspace, setQueryIndex, selectedSession, getSanitizedQueryParam]);

  const headers = React.useMemo(
    () => ({
      "x-client-id": queryParamClientId ?? FREE_CLIENT_ID,
      "x-app-id": queryParamAppId ?? FREE_APP_ID,
    }),
    [queryParamClientId, queryParamAppId]
  );

  const tabs = React.useMemo(() => getTabs(headers), [headers]);

  return (
    <div className="PlaygroundWrapper">
      <Modal
        modalIsOpen={modalIsOpen}
        onCloseModal={closeModal}
        clientId={clientIdState}
        appId={appIdState}
        setCredentials={setCredentials}
      />

      <Playground
        endpoint={process.env.REACT_APP_API_ENDPOINT}
        subscriptionEndpoint={process.env.REACT_APP_API_SUBSCRIPTION_ENDPOINT}
        shareEnabled={null}
        setTitle={false}
        settings={{
          "editor.cursorShape": "line",
          "editor.fontFamily": `'Source Code Pro', 'Consolas', 'Inconsolata', 'Droid Sans Mono', 'Monaco', monospace`,
          "editor.fontSize": 14,
          "editor.reuseHeaders": true,
          "editor.theme": "dark",
          "general.betaUpdates": false,
          "prettier.useTabs": true,
          "request.credentials": "omit",
          "schema.disableComments": true,
          "schema.polling.enable": true,
          "tracing.hideTracingResponse": true,
        }}
        showNewWorkspace={false}
        canSaveConfig={false}
        workspaceName={"Chargetrip Playground"}
        tabs={tabs}
      />
    </div>
  );
});
